import { render, staticRenderFns } from "./HelpCates.vue?vue&type=template&id=2d5d6eb6&"
import script from "./HelpCates.vue?vue&type=script&lang=js&"
export * from "./HelpCates.vue?vue&type=script&lang=js&"
import style0 from "./HelpCates.vue?vue&type=style&index=0&id=2d5d6eb6&prod&lang=less&"
import style1 from "./HelpCates.vue?vue&type=style&index=1&id=2d5d6eb6&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports