<template>
  <a-card :bordered="false">
    <div style="margin-bottom: 10px;">
      <a-row :gutter="48">
        <a-col :md="8" :sm="24">
          <span>
            <a-button @click="goAddCates()">添加节点</a-button>
          </span>
        </a-col>
      </a-row>
    </div>

    <a-table v-if="tableData.length" bordered :columns="columns" :dataSource="tableData"
      :defaultExpandAllRows="defaultExpandAllRows" :loading="tableLoading" rowKey="id">
      <span slot="name" slot-scope="text, record">
        <editable-cell :text="text" @change="onCellChange(record, 'name', $event)" />
      </span>
      <span slot="action" slot-scope="text, record">
        <a v-if="record.pid == 0" @click="goAddCates(record.id)">添加</a>
        <a-divider v-if="record.pid == 0" type="vertical" />
        <a-popconfirm v-if="tableData.length" title="确定删除该节点?" @confirm="() => onDelete(record.id)">
          <a href="javascript:;">删除</a>
        </a-popconfirm>
      </span>
    </a-table>
  </a-card>
</template>

<script>
import { EditableCell } from '@/components'

const columns = [
  {
    title: 'ID',
    dataIndex: 'id'
  },
  {
    title: '名称',
    dataIndex: 'name',
    scopedSlots: { customRender: 'name' }
  },
  {
    title: '父级ID',
    dataIndex: 'pid'
  },
  {
    title: '操作',
    width: '150px',
    scopedSlots: { customRender: 'action' }
  }]

export default {
  name: 'HelpCates',
  components: {
    EditableCell
  },
  data() {
    return {
      columns,
      tableData: [],
      tableLoading: false,
      defaultExpandAllRows: true
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.tableLoading = true
      this.$http({
        url: '/admin/helps/cates_tree'
      }).then(res => {
        if (res.status === 'success') {
          this.tableData = res.data
        } else {
          this.$notification.error({
            message: res.status,
            description: res.message
          })
        }
      }).finally(() => {
        this.tableLoading = false
      })
    },
    goAddCates(id) {
      if (id !== undefined) {
        this.$router.push({ name: 'HelpCatesAdd', params: { pid: id } })
      } else {
        this.$router.push({ name: 'HelpCatesAdd' })
      }
    },
    onCellChange(record, dataIndex, value) {
      record[dataIndex] = value
      delete record.children
      // 执行更新
      this.$http({
        url: '/admin/helps/cates_save',
        method: 'post',
        data: record
      }).then(res => {
        if (res.status === 'success') {
          this.loadData()
        } else {
          this.$notification.error({
            message: res.status,
            description: res.message
          })
        }
      })
    },
    onDelete(id) {
      // 调用删除
      this.$http({
        url: '/admin/helps/cates_delete',
        method: 'post',
        data: { 'id': id }
      }).then(res => {
        if (res.status === 'success') {
          this.$notification.success({
            message: res.status,
            description: '删除成功'
          })
        } else {
          this.$notification.error({
            message: res.status,
            description: res.message
          })
        }
      })
    }
  }
}
</script>

<style lang="less">
.custom-tree {

  /deep/ .ant-menu-item-group-title {
    position: relative;

    &:hover {
      .btn {
        display: block;
      }
    }
  }

  /deep/ .ant-menu-item {
    &:hover {
      .btn {
        display: block;
      }
    }
  }

  /deep/ .btn {
    display: none;
    position: absolute;
    top: 0;
    right: 10px;
    width: 20px;
    height: 40px;
    line-height: 40px;
    z-index: 1050;

    &:hover {
      transform: scale(1.2);
      transition: 0.5s all;
    }
  }
}
</style>
<style>
.editable-cell {
  position: relative;
}

.editable-cell-input-wrapper,
.editable-cell-text-wrapper {
  padding-right: 24px;
}

.editable-cell-text-wrapper {
  padding: 5px 24px 5px 5px;
}

.editable-cell-icon,
.editable-cell-icon-check {
  position: absolute;
  right: 0;
  width: 20px;
  cursor: pointer;
}

.editable-cell-icon {
  line-height: 18px;
  display: none;
}

.editable-cell-icon-check {
  line-height: 28px;
}

.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}

.editable-cell-icon:hover,
.editable-cell-icon-check:hover {
  color: #108ee9;
}

.editable-add-btn {
  margin-bottom: 8px;
}
</style>
